import { Icon } from "@combateafraude/react";
import { useAPIContext } from "@contexts/API";
import { useFlowContext } from "@contexts/Flow";
import { useOnboardingOptionsContext } from "@contexts/OnboardingOptions";
import { useOnboardingStepsContext } from "@contexts/OnboardingSteps";
import {
  doNotAllowRemoveDoneStep,
  elementsToSteps,
  layoutElements,
  stepsToElements,
  stepToElement,
  updateElementStepData,
} from "@utils/flow";
import { Logger } from "@utils/logging";
import { parseStep } from "@utils/onboarding";
import { message } from "antd";
import isEqual from "lodash.isequal";
import { useRef } from "react";
import { useCallback, useEffect, useState } from "react";
import ReactFlow, { addEdge, Background, ControlButton, Controls, isEdge, removeElements } from "react-flow-renderer";
import { useTranslation } from "react-i18next";

import { StepEdge } from "./Edges";
import { DefaultStepNode } from "./Nodes";

/** Turns to true when "initialOnboardingSteps" changes, meaning the next "onboardingSteps" change should be treated as initial.
 *  Used as var for instant availability. */
var initialStepsChanged = false;

const I18N_BASE_PATH = "src.components.flowEditor";

const FlowEditor = () => {
  const { t } = useTranslation();
  const { tenantId } = useAPIContext();

  const reactFlowRef = useRef(null);
  const [shouldFitView, setShouldFitView] = useState(false);

  const { initialOnboardingSteps, onboardingSteps, setOnboardingSteps } = useOnboardingStepsContext();
  const { isTemplateDefault, templateTheme } = useOnboardingOptionsContext();
  const { setIsConnecting, elements, setElements, reactFlowInstance, setReactFlowInstance, setReactFlowRef } =
    useFlowContext();

  useEffect(() => {
    if (initialOnboardingSteps) initialStepsChanged = true;
  }, [initialOnboardingSteps]);

  useEffect(() => {
    setReactFlowRef(reactFlowRef);
  }, [reactFlowRef]);

  useEffect(() => {
    if (shouldFitView && reactFlowInstance) {
      reactFlowInstance.fitView();
      setShouldFitView(false);
    }
  }, [reactFlowInstance, shouldFitView]);

  // Initially gets elements from steps, then only updates the data attribute
  useEffect(() => {
    if (onboardingSteps) {
      Logger.info("onboardingSteps", onboardingSteps);
      // O trecho abaixo será removido em breve, pois a opção de áudios estará disponível para todos assim que as gravações forem melhoradas
      const tenants = {
        dev: ["9c0fabad-41e0-4bde-8451-3e53352d116f"], // PS
        beta: [
          "7c4de253-88fc-40e5-ae98-39974e5885f6", // PS
          "92d234dd-db83-4953-b421-3cc9f82c82b2", // PARANÁ BANCO
          "4bd39ffa-5a84-4154-b900-45cd1620c4c9", // PARANÁ BANCO
        ],
        prod: [
          "335dbb7c-416f-4d59-ab4e-c559d3e4126a", // PS
          "9d6c73bc-3a46-48b9-b9ff-4d46763b1f9d", // PARANÁ BANCO
          "f6311a5f-ad3f-406f-a121-2e039ce7763e", // PARANÁ BANCO
          "20990ce7-7403-4bd9-9c54-21c3ab8a14db", // PARANÁ BANCO
        ],
      };
      Logger.info("tenantId", tenantId);
      if (tenants[process.env.REACT_APP_ENV].includes(tenantId)) {
        const DD_STEP = onboardingSteps.find((step) => step.name === "DD-0");
        if (DD_STEP) {
          const DD_STEP_CUSTOM_CONFIGS = DD_STEP.customizables.find(
            (customConfig) => customConfig === "STEP_CUSTOM-CONFIGURATIONS-DD",
          );

          if (DD_STEP_CUSTOM_CONFIGS) {
            DD_STEP.customizables[DD_STEP.customizables.indexOf("STEP_CUSTOM-CONFIGURATIONS-DD")] =
              "STEP_CUSTOM-CONFIGURATIONS-DD-TEMP";
          }
        }
      }
      // O trecho acima será removido em breve

      /**
       * Start - para fluxos internacionais, forçar a configuração do modo de captura para manual no DD, pois o
       * automático ainda não está disponível para documentos internacionais.
       */
      if (templateTheme?.isBrazilianFlow === false) {
        const DD_STEP = onboardingSteps.find((step) => parseStep(step?.name)?.[0] === "DD");
        if (DD_STEP?.sdkOptions?.capture?.captureSettings?.mode) {
          DD_STEP.sdkOptions.capture.captureSettings.mode = "manual";
        }
      }
      /**
       * End
       */
      if (initialStepsChanged) {
        let newElements = stepsToElements(onboardingSteps, elements);
        if (!isEqual(elements, newElements)) {
          Logger.console("setElements initialize", newElements);
          setElements(newElements);
          setTimeout(() => setShouldFitView(true), 250);
          initialStepsChanged = false;
        }
      } else {
        let newElements = updateElementStepData(elements, onboardingSteps);
        if (!isEqual(elements, newElements)) {
          Logger.console("setElements", newElements);
          setElements(newElements);
        }
      }
    }
  }, [onboardingSteps]);

  // Updates onboardingSteps whenever elements change
  useEffect(() => {
    if (elements) {
      let newSteps = elementsToSteps(elements);
      if (!isEqual(onboardingSteps, newSteps)) {
        Logger.console("setOnboardingSteps", newSteps);
        setOnboardingSteps(newSteps);
      }
    }
  }, [elements]);

  // EVENTS
  const onElementsRemove = useCallback(
    (elementsToRemove) => {
      const triedToRemoveDoneStep = doNotAllowRemoveDoneStep(elementsToRemove);
      if (triedToRemoveDoneStep)
        return message.error(
          t(
            `${I18N_BASE_PATH}.methods.onElementsRemove.removeDoneStepText`,
            'Não é possível remover a etapa "Fim" do fluxo, pois é obrigatória',
          ),
        );

      let newElements = removeElements(elementsToRemove, [...elements]);
      newElements = updateElementStepData(newElements);
      setElements(newElements);
    },
    [elements, setElements, t],
  );
  const onConnect = useCallback(
    (params) => {
      const { source, target, sourceHandle } = params;
      if (source === target) return;

      const isSourceSmartChoice = source.includes("SMART_CHOICE");

      const getItemsRelativeToDocumentType = (array) => {
        const filteredArray = array.filter((item) => !item.id.includes("->"));
        const index = filteredArray.findIndex((item) => item.id.includes("DOCUMENT_TYPE"));

        if (index === -1) return [[], []]; // Caso não encontre "DOCUMENT_TYPE" no array

        const elementsBeforeDocumentType = filteredArray.slice(0, index).map((item) => item.id); // Pega os itens antes do índice do elemento "DOCUMENT_TYPE"
        const elementsAfterDocumentType = filteredArray.slice(index + 1).map((item) => item.id); // Pega os itens após o índice do elemento "DOCUMENT_TYPE"

        return [elementsAfterDocumentType, elementsBeforeDocumentType];
      };

      const [elementsAfterDocumentType, elementsBeforeDocumentType] = getItemsRelativeToDocumentType(elements);

      const enabledElements = elements
        .filter((el) => el.data?.step?.isEnabled)
        .map((el) => !el.id.includes("->") && el.id);

      let newElements = [...elements].filter((el) => {
        if ((isSourceSmartChoice && !isEdge(el)) || (isSourceSmartChoice && el.target === target)) {
          return true;
        }
        // Only filters edges
        if (!isEdge(el)) return true;
        // Only allows handles to connect to 1 node
        if (sourceHandle) {
          if (el.target === target) return false;
          if (!el.sourceHandle) return true;
          if (el.sourceHandle === sourceHandle) {
            return false;
          }
          return true;
        }
        // Only allows one target per node (not allowing source and target to connect to each other)
        return !(el.source === target && el.target === source) && el.source !== source;
      });

      const isTargetElementValid =
        (elementsAfterDocumentType.includes(target) && !elementsBeforeDocumentType.includes(target)) ||
        (enabledElements.includes(target) && !elementsAfterDocumentType.includes(target));

      if ((isSourceSmartChoice && isTargetElementValid && !target.includes("DOCUMENT_TYPE")) || !isSourceSmartChoice) {
        newElements = addEdge(
          {
            id: `${source}->${target}${sourceHandle ? `(${sourceHandle})` : ""}`,
            ...params,
            arrowHeadType: "arrow",
            type: "stepEdge",
          },
          newElements,
        );

        newElements = updateElementStepData(newElements);
        setElements(newElements);
      }
    },
    [elements, setElements],
  );
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowRef.current.getBoundingClientRect();
      const step = JSON.parse(event.dataTransfer.getData("application/reactflow"));
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      setElements((els) => [...els, stepToElement(step, position)]);
    },
    [reactFlowRef, reactFlowInstance, setElements],
  );
  const onLayoutElements = useCallback(() => {
    let newElements = layoutElements(elements);
    setElements(newElements);
  }, [elements, setElements]);

  return (
    <ReactFlow
      ref={reactFlowRef}
      elements={elements}
      // CONFIGURATION
      deleteKeyCode={isTemplateDefault ? 46 : null} /* DELETE key */
      snapToGrid={true}
      snapGrid={[10, 10]}
      minZoom={0.2}
      selectNodesOnDrag={false}
      // STYLE
      connectionLineType="smoothstep"
      arrowHeadColor="var(--color-caf-primary)"
      style={{ background: "#474D6A" }}
      // EVENTS
      onLoad={setReactFlowInstance}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onConnectStart={() => setIsConnecting(true)}
      onConnectEnd={() => setIsConnecting(false)}
      // TYPES
      nodeTypes={{ defaultStep: DefaultStepNode }}
      edgeTypes={{ stepEdge: StepEdge }}
    >
      <Controls showInteractive={false}>
        <ControlButton
          onClick={onLayoutElements}
          title={t(`${I18N_BASE_PATH}.components.reactFlow.controlButton`, "Organizar nodos")}
        >
          <Icon icon="first" />
        </ControlButton>
      </Controls>
      <Background color="#777" gap={10} />
    </ReactFlow>
  );
};

export default FlowEditor;
